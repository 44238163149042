module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src3674767614/src/gatsby-mos/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/Favicon-512x512.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://michiganoralsurgeons.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MF6BTSM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michigan Oral Surgeons","lang":"en","display":"standalone","short_name":"Michigan Oral Surgeons","description":"We provide compassionate and comprehensive oral surgery care at our state-of-the-art practice in Ann Arbor, Plymouth, Adrian, and Dexter, MI.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/Favicon-512x512.jpg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d5e284b1792823b9ab422833acc3b5dc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
